import NextImage from 'next/image';
import { useIsMounted } from '@pancakeswap/hooks';
import { AtomBox } from '@pancakeswap/ui/components/AtomBox';
import throttle from 'lodash/throttle';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import BottomNav from '../../components/BottomNav';
import { Box } from '../../components/Box';
import CakePrice from '../../components/CakePrice/CakePrice';
import MenuItems from '../../components/MenuItems/MenuItems';
import { SubMenuItems } from '../../components/SubMenuItems';
import { useMatchBreakpoints } from '../../contexts';
import Logo from './components/Logo';
import { MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from './config';
import { MenuContext } from './context';
import { NavProps } from './types';
import { Flex, Image } from '@pancakeswap/uikit';
import { useRouter } from 'next/router';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  // display: grid;
  // grid-template-rows: auto 1fr;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.875rem;
  transform: translate3d(0, 0, 0);
  padding-left: 16px;
  padding-right: 16px;
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
  background: ${({ theme }) => theme.colors.background};
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  padding-bottom: 50px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 0px;
  }
`;

const TextHeader = styled.h2`
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2.1px;
  text-transform: uppercase;
  max-width: 1600px;
  margin: 0 auto;
  line-height: 120%;
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.mediaQueries.xxl} {
    font-size: 40px;
  }

  @media (min-width: 580px) {
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
  }
`;

const TextHighlight = styled.span`
  color: #5b46f6;
`;

const TextDesc = styled.p`
  color: #b4b4b4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.54px;
  margin-top: 24px;

  @media (min-width: 580px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
`;

const ImagePattern = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 400px;
  height: 400px;
`;

const PaddingTop = styled.div`
  height: 106px;

  @media (max-width: 768px) {
    height: 86px;
  }
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = 'a',
  banner,
  rightSide,
  // isDark,
  // toggleTheme,
  // currentLang,
  // setLang,
  // cakePriceUsd,
  links,
  subLinks,
  // footerLinks,
  activeItem,
  activeSubItem,
  // langs,
  // buyCakeLabel,
  children,
}) => {
  const { pathname } = useRouter();
  // const hasSubLinks = subLinks && subLinks.length > 0;
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home');

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);

  const showTitle = (): { title: string[]; desc: string } | null => {
    switch (pathname) {
      case '/farms':
        return {
          title: ['The Ultimate Guide to Earning Crypto with', 'Liquidity Farming', 'and', 'Staking'],
          desc: 'The Power of Staking: How to Earn Passive Income Through Proof-of-Stake Mechanisms and Increase Your Crypto Holdings',
        };
      case '/pools':
        return {
          title: ['The Ultimate Guide to Earning Crypto with', 'Liquidity Farming', 'and', 'Staking'],
          desc: 'The Power of Staking: How to Earn Passive Income Through Proof-of-Stake Mechanisms and Increase Your Crypto Holdings',
        };
      case '/pools/history':
        return {
          title: ['The Ultimate Guide to Earning Crypto with', 'Liquidity Farming', 'and', 'Staking'],
          desc: 'The Power of Staking: How to Earn Passive Income Through Proof-of-Stake Mechanisms and Increase Your Crypto Holdings',
        };
      case '/create-pool':
        return {
          title: ['YOUR OWN ', 'STAKE'],
          desc: 'Set Your Own Terms: Create a Stake That Fits Your Needs',
        };
      case '/my-stake':
        return {
          title: ['YOUR OWN ', 'STAKE'],
          desc: 'Set Your Own Terms: Create a Stake That Fits Your Needs',
        };
      case '/launchpad':
        return {
          title: ['Discovering', 'Hidden Gems', ': Investing Through Crypto', 'Launchpads'],
          desc: 'The Benefits of Launchpads: Why Early-Stage Investments Through Launchpads Can Be Highly Profitable and Transformative.',
        };
      default:
        return null;
    }
  };

  // const ShowTitle = showTitle();

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <AtomBox
        position="relative"
        asChild
        minHeight={{
          xs: 'auto',
          md: '100vh',
        }}
      >
        <Wrapper style={{ paddingBottom: 50 }}>
          <ImagePattern>
            <Image src="/images/polygon.png" width={400} height={400} />
          </ImagePattern>
          <NextImage
            style={{ position: 'absolute', left: 0, bottom: 0 }}
            src="/images/hero.png"
            width={300}
            height={400}
            alt="hero"
          />
          <NextImage
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSIxMDgwIiB2aWV3Qm94PSIwIDAgMTkyMCAxMDgwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzIxMTFfMzQ3MykiPgo8Y2lyY2xlIGN4PSI5NjAiIGN5PSIxMzcwIiByPSI4MzMiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA5NjAgMTM3MCkiIGZpbGw9IiM1QjQ2RjYiIGZpbGwtb3BhY2l0eT0iMC4xMyIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2ZfMjExMV8zNDczIiB4PSItNTczIiB5PSItMTYzIiB3aWR0aD0iMzA2NiIgaGVpZ2h0PSIzMDY2IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9InNoYXBlIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjM1MCIgcmVzdWx0PSJlZmZlY3QxX2ZvcmVncm91bmRCbHVyXzIxMTFfMzQ3MyIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K"
            fill
            alt="hero"
          />
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            {banner && isMounted && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>}
            <StyledNav>
              <Logo href={homeLink?.href ?? '/swap'} />
              <Flex
                alignItems="center"
                width="100%"
                height="100%"
                gap="24px"
                justifyContent={isMobile ? 'center' : 'flex-end'}
              >
                <AtomBox display={{ xs: 'none', md: 'block' }}>
                  <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                </AtomBox>
                {/* <div style={{ width: '2px', height: '48px', backgroundColor: '#25282C' }} /> */}
                {/* <AtomBox mr="12px" display={{ xs: 'none', lg: 'block' }}>
                  <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                </AtomBox> */}
                {rightSide}
              </Flex>
            </StyledNav>
          </FixedContainer>
          <PaddingTop />
          {/* {ShowTitle != null && !isMobile && (
            <Box backgroundColor="#141414" style={{ paddingBottom: '40px' }}>
              <TextHeader>
                {ShowTitle.title[0]} <TextHighlight>{ShowTitle.title[1]} </TextHighlight> {ShowTitle.title[2]}{' '}
                <TextHighlight>{ShowTitle.title[3]}</TextHighlight>{' '}
              </TextHeader>
              <TextDesc>{ShowTitle.desc}</TextDesc>
            </Box>
          )} */}

          {/* <Box zIndex="1">
            {subLinks ? (
              <Flex justifyContent="space-around" overflow="hidden">
                <SubMenuItems
                  items={subLinksWithoutMobile}
                  style={{ marginTop: `${totalTopMenuHeight + 1}px`, display: 'block' }}
                  activeItem={activeSubItem}
                />
                {subLinksMobileOnly && subLinksMobileOnly?.length > 0 && (
                  <SubMenuItems
                    items={subLinksMobileOnly}
                    style={{ marginTop: `${totalTopMenuHeight + 1}px`, display: 'block' }}
                    activeItem={activeSubItem}
                    isMobileOnly
                  />
                )}
              </Flex>
            ) : (
              <div />
            )}
          </Box> */}
          <BodyWrapper style={{ marginTop: 0, background: 'transparent' }}>
            {/* <BodyWrapper
            style={{ marginTop: !subLinks ? `${totalTopMenuHeight + 1}px` : '0', background: 'transparent' }}
          > */}
            <Inner>{children}</Inner>
          </BodyWrapper>

          {/* <Box position="absolute" bottom={0} right={0} left={0}>
            <Image src="/images/ellipse.png" width={1666} height={1666} />
          </Box> */}
        </Wrapper>
      </AtomBox>
      <AtomBox display={{ xs: 'block', md: 'none' }}>
        <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
      </AtomBox>
    </MenuContext.Provider>
  );
};

export default Menu;
