import { createAsyncThunk } from '@reduxjs/toolkit'
import { BridgeHistory } from './types'
import axios from 'axios'

const apiUrl = process.env.NEXT_PUBLIC_STAKING_API_URL

export const fetchHistoryBridge = createAsyncThunk<BridgeHistory[], void>(
  'bridge/fetchHistory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiUrl}/bridges`)
      return response.data
    } catch (error) {
      return rejectWithValue('Failed to fetch address')
    }
  },
)
