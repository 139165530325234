import IceChain from '../ice-chain'

const fantomTestExplorer = { name: 'FTMScan', url: 'https://testnet.ftmscan.com' }

export const ftmtest: IceChain = {
  id: 4002,
  name: 'Fantom Testnet',
  features: [],
  network: 'fantom',
  rpcUrls: {
    public: 'https://rpc.testnet.fantom.network',
    default: 'https://rpc.testnet.fantom.network',
  },
  blockExplorers: {
    default: fantomTestExplorer,
    etherscan: fantomTestExplorer,
  },
  nativeCurrency: {
    name: 'Test Fantom Native Token',
    symbol: 'FTM',
    decimals: 18,
  },
  blockInterval: 1,
  multicall: {
    address: '0x1234567890abcdef1234567890abcdef12345678',
    blockCreated: 5000000,
  },
  wrappedNative: {
    address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    decimals: 18,
    symbol: 'WFTM',
    name: 'Wrapped Fantom',
  },
}
