import { bsc } from './bsc'
import { bsctest } from './bscTest'
import { beth } from './beth'
import { matic } from './matic'
import { ftmtest } from './ftmTest'

export const chainMap = {
  bsc,
  bsctest,
  beth,
  matic,
  ftmtest,
}

export const chains = Object.values(chainMap)

export const getChain = (chainId: number) => {
  return chains.find((chain) => chain.id === chainId)
}

export enum ChainId {
  BSC = bsc.id,
  TBSC = bsctest.id,
  BETH = beth.id,
  MATIC = matic.id,
  TFTM = ftmtest.id,
}
