import { error } from 'itty-router-extras'
import { createReducer, createSlice } from '@reduxjs/toolkit'
import { BridgeAddress, BridgeCurrency, BridgeHistory, BridgeRatio } from './types'
import { fetchAddressBridge } from './fetchAddressBridge'
import { fetchCurrenciesBridge } from './fetchCurrenciesBridge'
import { fetchHistoryBridge } from './fetchHistoryBridge'
import { fetchRatioBridge } from './fetchRatioBridge'
import { createBridge } from './createBridge'

interface BridgeState {
  currentBridge: BridgeHistory | null
  address: BridgeAddress[]
  currencies: BridgeCurrency[]
  history: BridgeHistory[]
  ratio: BridgeRatio[]
  loadingBridge: boolean
  errorBridge: string | null
  loadingCreateBridge: boolean
  errorCreateBridge: string | null
  successCreateBridge: boolean
  loadingAddress: boolean
  errorAddrees: string | null
  loadingCurrency: boolean
  errorCurrency: string | null
  loadingHistory: boolean
  errorHistory: string | null
  loadingRatio: boolean
  errorRatio: string | null
}

// Initial State
const initialState: BridgeState = {
  currentBridge: null,
  address: [],
  currencies: [],
  history: [],
  ratio: [],
  loadingBridge: false,
  errorBridge: null,
  loadingCreateBridge: false,
  errorCreateBridge: null,
  successCreateBridge: false,
  loadingAddress: false,
  errorAddrees: null,
  loadingCurrency: false,
  errorCurrency: null,
  loadingHistory: false,
  errorHistory: null,
  loadingRatio: false,
  errorRatio: null,
}

const bridgeReducer = createSlice({
  name: 'bridge',
  initialState,
  reducers: {
    setSuccessCreateBridge(state, action) {
      state.successCreateBridge = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBridge.pending, (state) => {
        state.loadingCreateBridge = true
        state.errorCreateBridge = null
        state.successCreateBridge = false
      })
      .addCase(createBridge.fulfilled, (state, action) => {
        state.loadingCreateBridge = false
        state.currentBridge = action.payload
        state.successCreateBridge = true
      })
      .addCase(createBridge.rejected, (state, action) => {
        state.loadingCreateBridge = false
        state.errorCreateBridge = action.error.message || 'Failed to create bridge'
        state.successCreateBridge = false
      })
      // Handle fetching address
      .addCase(fetchAddressBridge.pending, (state) => {
        state.loadingAddress = true
        state.errorAddrees = null
      })
      .addCase(fetchAddressBridge.fulfilled, (state, action) => {
        state.loadingAddress = false
        state.address = action.payload
      })
      .addCase(fetchAddressBridge.rejected, (state, action) => {
        state.loadingAddress = false
        state.errorAddrees = action.error.message || 'Failed to fetch address'
      })

      // Handle fetching currencies
      .addCase(fetchCurrenciesBridge.pending, (state) => {
        state.loadingCurrency = true
        state.errorCurrency = null
      })
      .addCase(fetchCurrenciesBridge.fulfilled, (state, action) => {
        state.loadingCurrency = false
        state.currencies = action.payload
      })
      .addCase(fetchCurrenciesBridge.rejected, (state, action) => {
        state.loadingCurrency = false
        state.errorCurrency = action.error.message || 'Failed to fetch currencies'
      })

      // Handle fetching history
      .addCase(fetchHistoryBridge.pending, (state) => {
        state.loadingHistory = true
        state.errorHistory = null
      })
      .addCase(fetchHistoryBridge.fulfilled, (state, action) => {
        state.loadingHistory = false
        state.history = action.payload
      })
      .addCase(fetchHistoryBridge.rejected, (state, action) => {
        state.loadingHistory = false
        state.errorHistory = action.error.message || 'Failed to fetch history'
      })

      // Handle fetching ratio
      .addCase(fetchRatioBridge.pending, (state) => {
        state.loadingRatio = true
        state.errorRatio = null
      })
      .addCase(fetchRatioBridge.fulfilled, (state, action) => {
        state.loadingRatio = false
        state.ratio = action.payload
      })
      .addCase(fetchRatioBridge.rejected, (state, action) => {
        state.loadingRatio = false
        state.errorRatio = action.error.message || 'Failed to fetch ratio'
      })
  },
})

export const { setSuccessCreateBridge } = bridgeReducer.actions

export default bridgeReducer.reducer

// // Reducer Implementation
// const bridgeReducer = createReducer(initialState, (builder) => {
//   builder
//     .addCase(createBridge.pending, (state) => {
//       state.loadingCreateBridge = true
//       state.errorCreateBridge = null
//       state.successCreateBridge = false
//     })
//     .addCase(createBridge.fulfilled, (state, action) => {
//       state.loadingCreateBridge = false
//       state.currentBridge = action.payload
//       state.successCreateBridge = true
//     })
//     .addCase(createBridge.rejected, (state, action) => {
//       state.loadingCreateBridge = false
//       state.errorCreateBridge = action.error.message || 'Failed to create bridge'
//       state.successCreateBridge = false
//     })

//     // Handle fetching address
//     .addCase(fetchAddressBridge.pending, (state) => {
//       state.loadingAddress = true
//       state.errorAddrees = null
//     })
//     .addCase(fetchAddressBridge.fulfilled, (state, action) => {
//       state.loadingAddress = false
//       state.address = action.payload
//     })
//     .addCase(fetchAddressBridge.rejected, (state, action) => {
//       state.loadingAddress = false
//       state.errorAddrees = action.error.message || 'Failed to fetch address'
//     })

//     // Handle fetching currencies
//     .addCase(fetchCurrenciesBridge.pending, (state) => {
//       state.loadingCurrency = true
//       state.errorCurrency = null
//     })
//     .addCase(fetchCurrenciesBridge.fulfilled, (state, action) => {
//       state.loadingCurrency = false
//       state.currencies = action.payload
//     })
//     .addCase(fetchCurrenciesBridge.rejected, (state, action) => {
//       state.loadingCurrency = false
//       state.errorCurrency = action.error.message || 'Failed to fetch currencies'
//     })

//     // Handle fetching history
//     .addCase(fetchHistoryBridge.pending, (state) => {
//       state.loadingHistory = true
//       state.errorHistory = null
//     })
//     .addCase(fetchHistoryBridge.fulfilled, (state, action) => {
//       state.loadingHistory = false
//       state.history = action.payload
//     })
//     .addCase(fetchHistoryBridge.rejected, (state, action) => {
//       state.loadingHistory = false
//       state.errorHistory = action.error.message || 'Failed to fetch history'
//     })

//     // Handle fetching ratio
//     .addCase(fetchRatioBridge.pending, (state) => {
//       state.loadingRatio = true
//       state.errorRatio = null
//     })
//     .addCase(fetchRatioBridge.fulfilled, (state, action) => {
//       state.loadingRatio = false
//       state.ratio = action.payload
//     })
//     .addCase(fetchRatioBridge.rejected, (state, action) => {
//       state.loadingRatio = false
//       state.errorRatio = action.error.message || 'Failed to fetch ratio'
//     })
// })

// export default bridgeReducer
