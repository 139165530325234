import React from 'react';
import styled from 'styled-components';
import Flex from '../../components/Box/Flex';
import { Box, MotionBox } from '../../components/Box';
import { ArrowBackIcon, CloseIcon } from '../../components/Svg';
import { IconButton } from '../../components/Button';
import { ModalProps } from './types';

export const mobileFooterHeight = 73;

export const ModalHeader = styled(Flex)<{ background?: string; withBorderBottom?: boolean }>`
  align-items: center;
  ${({ withBorderBottom, theme }) => withBorderBottom && `border-bottom: 1px solid ${theme.colors.cardBorder};`}
  padding-bottom: 1rem;
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - ${mobileFooterHeight}px);
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    max-height: 90vh;
  }
`;

export const ModalCloseButton: React.FC<React.PropsWithChildren<{ onDismiss: ModalProps['onDismiss'] }>> = ({
  onDismiss,
}) => {
  return (
    // <IconButton
    //   width={['1rem !important', null, null, null, '24px !important']}
    //   height={['1rem !important', null, null, null, '24px !important']}
    //   variant="text"
    //   onClick={onDismiss}
    //   aria-label="Close the dialog"
    //   style={{ background: 'none' }}
    // >
    // </IconButton>
    <Box aria-label="Close the dialog" size="1.5rem" onClick={onDismiss} style={{ cursor: 'pointer' }}>
      <CloseIcon width="1.5rem" />
    </Box>
  );
};

export const ModalBackButton: React.FC<React.PropsWithChildren<{ onBack: ModalProps['onBack'] }>> = ({ onBack }) => {
  return (
    // <IconButton
    //   variant="text"
    //   width="24px !important"
    //   onClick={onBack}
    //   scale="xs"
    //   area-label="go back"
    //   mr={1}
    //   style={{ background: 'none' }}
    // >
    //   <ArrowBackIcon />
    // </IconButton>
    <ArrowBackIcon area-label="go back" onClick={onBack} style={{ cursor: 'pointer' }} />
  );
};

export const ModalContainer = styled(MotionBox)<{ $minWidth: string; $maxWidth: string }>`
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 0px !important;
  background: ${({ theme, background }) => background || theme.colors.background};
  padding: 1.5rem;
  box-shadow: 0px 0px 8px 0px ${({ theme }) => theme.colors.primary};
  width: 100%;
  max-width: 25rem;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: absolute;
  min-width: ${({ $minWidth }) => $minWidth};
  bottom: 0;
  /* min-height: 250px; */

  ${({ theme }) => theme.mediaQueries.md} {
    /* width: auto;
    position: auto; */
    bottom: auto;
    max-height: 100vh;
  }

  @media (max-width: 768px) {
    width: 100vw;
    max-width: 100vw;
  }
`;
