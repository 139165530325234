import { INIT_CODE_HASH } from '../common/swap'
import IceChain from '../ice-chain'

export const matic: IceChain = {
  id: 137,
  name: 'Polygon Mainnet',
  features: [],
  network: 'matic',
  rpcUrls: {
    public: 'https://rpc.ankr.com/polygon/36de70614fd28e2c5dd98d9f113f3aca91a5ffaa2fa563a44027c91331f4f7f5',
    default: 'https://rpc.ankr.com/polygon/36de70614fd28e2c5dd98d9f113f3aca91a5ffaa2fa563a44027c91331f4f7f5',
  },
  blockExplorers: {
    default: { name: 'PolygonScan', url: 'https://polygonscan.com/' },
  },
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  blockInterval: 10,
  multicall: {
    address: '0x07C1285BD5552E47f855cDA71431d0E44Ab6130D',
    blockCreated: 51662066,
  },
  wrappedNative: {
    address: '0x878dd5259Aafbb79541aC961E0928f2e4fA99cFe',
    decimals: 18,
    symbol: 'WMATIC',
    name: 'Wrapped MATIC',
  },
  swap: {
    factoryAddress: '0x545b445DB4A3808846f1AAe6cdc3CC30c6569575',
    initCodeHash: INIT_CODE_HASH,
  },
}
