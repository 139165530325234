import { ChainId } from '@pancakeswap/sdk'

const chainName: Record<ChainId, string> = {
  [ChainId.BSC]: 'Binance',
  [ChainId.TBSC]: 'Binance Testnet',
  [ChainId.BETH]: 'Betherance',
  [ChainId.MATIC]: 'Polygon',
  [ChainId.TFTM]: 'Fantom Testnet',
}

export default chainName
