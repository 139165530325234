import { createAsyncThunk } from '@reduxjs/toolkit'
import { BridgeRatio } from './types'
import axios from 'axios'

const apiUrl = process.env.NEXT_PUBLIC_STAKING_API_URL

export const fetchRatioBridge = createAsyncThunk<BridgeRatio[], void>(
  'bridge/fetchRatio',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiUrl}/public/ratio`)
      return response.data
    } catch (error) {
      return rejectWithValue('Failed to fetch address')
    }
  },
)
