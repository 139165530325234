import IceChain from '../ice-chain'

const bscTestExplorer = { name: 'BscScan', url: 'https://testnet.bscscan.com' }

export const bsctest: IceChain = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  features: [],
  network: 'binance',
  rpcUrls: {
    public: 'https://bsc-testnet-rpc.publicnode.com',
    default: 'https://bsc-testnet-rpc.publicnode.com',
  },
  blockExplorers: {
    default: bscTestExplorer,
    etherscan: bscTestExplorer,
  },
  nativeCurrency: {
    name: 'Test Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  blockInterval: 5,
  multicall: {
    address: '0xCCB178A6b42864c808C8acDDAE915A30b6774a85',
    blockCreated: 43202987,
  },
  swap: {
    factoryAddress: '0x1B798B19AD843b22BF66D5a70Ef4a39BA9F2E962',
    initCodeHash: '0x6c4d683a0d60080f85c3568b38091bcd20111b695602aa1cb64e5f2b46a9ddf8',
  },
  wrappedNative: {
    address: '0x9bdF11577B9cb7789E13D9487708Cc3f2C324DAD',
    decimals: 18,
    symbol: 'WBNB',
    name: 'Wrapped BNB',
  },
  locks: {
    factoryAddress: '0x91cAe1Add4f70B50B31eA6f70869eEf0690E8D20',
  },
}
