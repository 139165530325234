import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const bsctestTokens = {
  wbnb: WETH9[ChainId.TBSC],
  usdt: new ERC20Token(
    ChainId.TBSC,
    '0xCf4E54700156e74918EaF77A9ab8C050C8b05890',
    18,
    'USDT',
    'USD Tether',
    'https://tether.to',
  ),
  cra: new ERC20Token(
    ChainId.TBSC,
    '0xdF2d8539b60BdACd2E77828eE1795cA54f5F0970',
    18,
    'ROBO',
    'ROBO Token',
    'https://craswap.com',
  ),
  bnb: new ERC20Token(
    ChainId.BSC,
    '0x9bdF11577B9cb7789E13D9487708Cc3f2C324DAD',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}
