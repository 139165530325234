import { createAsyncThunk } from '@reduxjs/toolkit'
import { BridgeAddress } from './types'
import axios from 'axios'

const apiUrl = process.env.NEXT_PUBLIC_STAKING_API_URL

export const fetchAddressBridge = createAsyncThunk<BridgeAddress[], void>(
  'bridge/fetchAddress',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiUrl}/public/bridgesAddress`)
      return response.data
    } catch (error) {
      return rejectWithValue('Failed to fetch address')
    }
  },
)
