import { createAsyncThunk } from '@reduxjs/toolkit'
import { BridgePayload, BridgeHistory } from './types'
import axios from 'axios'

const apiUrl = process.env.NEXT_PUBLIC_STAKING_API_URL

const getAuthToken = () => {
  return localStorage.getItem('authToken') || ''
}

export const createBridge = createAsyncThunk<BridgeHistory, BridgePayload>(
  'bridge/createBridge',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/bridges`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
        },
      })
      return response.data
    } catch (error: any) {
      // Handle and return meaningful error messages
      return rejectWithValue(error.response?.data?.message || 'Failed to create bridge')
    }
  },
)
